<template>

  <!--------------------------------------Full width Mode ------------------>
  <div v-if="isFullWidth"
    class="fixed-grid has-12-cols is-align-self-center"
    @click="event => $emit('handleClick', event)">
    <div class="grid is-align-items-center">

      <!-- Description, belnd and report -->
      <div class="cell is-col-span-6">
        <div class="fixed-grid has-12-cols">
          <div class="grid is-align-items-center">

            <!-- Description -->
            <div class="cell is-col-span-11 is-flex is-flex-direction-column">
              <slot name="description" />
            </div>

            <!-- Report -->
            <div class="cell is-col-span-1 is-clickable">
              <slot name="report" />
            </div>

          </div>
        </div>
      </div>

      <!----------- Rates   -------------->
      <div class="cell">
        <slot name="rates"
          v-if="isQuoteHour" />
      </div>

      <!---------- hour value ------------->
      <div class="cell">
        <slot name="hour_value" />
      </div>

      <!---------- Total value ------------->
      <div class="cell is-col-span-2">
        <slot name="total_value" />
      </div>

      <!---------- Present As ------------->
      <div class="cell">
        <slot name="present_as" />
      </div>

      <!---------- Actions ------------->
      <div class="cell end is-align-items-center">
        <div class="mt-1 is-flex">
          <slot name="assessment_status" />
          <slot name="delete" />
          <slot name="draggable" />
        </div>
      </div>

    </div>
  </div>

  <!------------------------------- Half screen width/ multi col view ------------>
  <div v-else
    @click="event => $emit('handleClick', event)"
    style="width: 100%; display: grid; grid-template-columns: 3% 97%;">

    <div>
      <div v-if="!isHeader"
        class="is-clickable"
        @click="$emit('handleClick', $event, true, isFullyExpanded)">
        <span class="mdi mdi-24px"
          :class="isFullyExpanded ? 'mdi-chevron-down' : 'mdi-chevron-right'" />
      </div>
    </div>

    <div class="fixed-grid has-12-cols is-align-self-center pos-relative">
      <div class="grid "
        style="row-gap: 5px;">
        <!-- Description, belnd and report -->
        <div class="cell is-col-span-6 is-flex is-align-items-center">
          <slot name="description" />
        </div>

        <!----------- Report/blend   -------------->
        <div class="cell is-flex is-align-items-center is-justify-content-space-evenly">
          <slot name="report" />
        </div>

        <!----------- Rates   -------------->
        <div class="cell is-flex is-align-items-center end">
          <slot name="rates"
            v-if="isQuoteHour" />
        </div>

        <!---------- hour value ------------->
        <div class="cell is-flex is-align-items-center end">
          <slot name="hour_value" />
        </div>

        <!---------- Total value ------------->
        <div class="cell is-col-span-2 end">
          <slot name="total_value" />
        </div>

        <!---------- Actions ------------->
        <div class="cell end ">
          <slot name="assessment_status" />
          <slot name="draggable" />
        </div>

        <!-- Line No -->
        <div class="cell is-col-span-2"
          v-if="isFullyExpanded && isSupportUser">
          <slot name="lineNo" />
        </div>

        <!-- Item No -->
        <div class="cell is-col-span-2"
          v-if="isFullyExpanded && isSupportUser">
          <slot name="itemNo" />
        </div>

        <!-- Item Status -->
        <div class="cell is-col-span-1"
          v-if="isFullyExpanded && isSupportUser">
          <slot name="itemStatus" />
        </div>

        <!-- Present as -->
        <div class="cell is-col-span-2 mt-2"
          v-if="isFullyExpanded">
          <slot name="present_as" />
        </div>

        <!-- empty slot if no items and not support user -->
        <div class="cell is-col-span-5"
          v-if="isFullyExpanded && !isSupportUser" />

        <!-- empty -->
        <div class="cell is-col-span-4"
          v-if="isFullyExpanded" />

        <!-- delete button -->
        <div class="cell end"
          v-if="isFullyExpanded">
          <slot name="delete" />
        </div>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'QuoteBuilderOtherLabourItemTemplate',
  props: {
    isHeader: {
      type: Boolean,
      default: true
    },
    isFullWidth: {
      type: Boolean,
      default: false
    },
    isFullyExpanded: {
      type: Boolean,
      default: false
    },
    // quote type dollar/hour
    isQuoteHour: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSupportUser() {
      return !!this.$userInfo.isSupportUser
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/themes/default/_variables.scss';
.bb-primary-1 {
  border-bottom: 2px solid $primary;
}
</style>