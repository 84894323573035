<template>
  <quote-builder-part-item-template :is-full-width="isFullWidth"
    class="has-text-primary has-text-weight-bold bb-primary-1 mb-2 has-background-white">
    <template v-slot:description>
      <div class="is-flex is-align-items-center has-text-weight-bold is-size-5">
        <span>Parts</span>
      </div>
    </template>
    <template v-slot:part_type>
      <div class="is-flex is-align-items-center mx-2 ">
        <span>Type</span>
      </div>
    </template>

    <template v-slot:part_qty>
      <div class="is-flex is-align-items-center is-justify-content-end ">
        <span>QTY</span>
      </div>
    </template>

    <template v-slot:part_buy>
      <div class="is-flex is-align-items-center is-justify-content-end ">
        <span>Buy Price</span>
      </div>
    </template>

    <template v-slot:part_markup>
      <div class="is-flex is-align-items-center is-justify-content-end ">
        <span>Markup</span>
      </div>
    </template>

    <template v-slot:part_sell>
      <div class="is-flex is-align-items-center is-justify-content-end ">
        <span>Unit Price</span>
      </div>
    </template>

    <template v-slot:total_value>
      <div class="is-flex is-justify-content-end is-align-items-center mx-2 ">
        <span>{{ $filters.formatCurrency(totalAmount, $userInfo.locale, 2) }}</span>
      </div>
    </template>

    <template v-slot:present_as>
      <div class="is-flex is-justify-content-center is-align-items-center">
        <span>Present As</span>
      </div>
    </template>

  </quote-builder-part-item-template>
</template>

<script>
import QuoteBuilderPartItemTemplate from '@/views/quote/quoteBuilder/templates/QuoteBuilderPartItemTemplate.vue'

export default {
  name: 'QuoteBuilderPartHeadingsComponent',
  components: {
    QuoteBuilderPartItemTemplate
  },
  props: {
    isFullWidth: {
      type: Boolean,
      default: true
    },
    totalAmount: {
      type: Number,
      default: 0
    }
  }
  // Your component's logic goes here
}
</script>

<style scoped>
/* Your component's styles go here */
</style>