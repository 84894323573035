<template>

  <!---------------------------------------------------------Quote Items  ---------------------------------------->
  <div>
    <div class="fixed-grid mx-2 has-1-cols-mobile quote-builder-cells-holder"
      :class="{
        'has-1-cols': selectedViewType == viewTypes.singleColView || selectedViewType == viewTypes.tabbedView,
        'has-2-cols': selectedViewType == viewTypes.multiColView}">
      <div class="grid">
        <!----------- Left hand section labours (RR, Repair, paint) ------------------>
        <div class="cell">
          <quote-builder-labour-component :inner-value="innerValue"
            v-if="((selectedTab.type == ItemCategoryTypes.RR || selectedTab.type == ItemCategoryTypes.REP
              ||selectedTab.type == ItemCategoryTypes.PAINT|| selectedViewType != viewTypes.tabbedView)
              && ((isAvailableItemsOpen && avaliableItemsType === 'LABOUR') || !isAvailableItemsOpen ))"
            :selected-tab="selectedTab"
            :is-full-width="isFullWidth"
            :view-types="viewTypes"
            :vehicle="vehicle"
            :edit-item-obj="editItemObj"
            :selected-view-type="selectedViewType"
            :fully-expanded="editItemObj.isFullyExpanded"
            :next-line-number="nextLineNumber"
            @onItemCopied="onItemCopied"
            @focusNextRow="id => handleFocusNextRow(id)"
            @newItemAdded="newItemAdded"
            @handleItemRowClick="handleItemRowClick"
            @itemRowClicked="handleItemRowClick"
            @handlePresentAs="itemId => $emit('handlePresentAs', itemId)" />

          <!-----------------------------Other Labour Component  --------------------------->
          <quote-builder-other-labour-component :inner-value="innerValue"
            v-if="((selectedTab.type == ItemCategoryTypes.MECH || selectedViewType != viewTypes.tabbedView)
              && ((isAvailableItemsOpen && avaliableItemsType === 'OTHER') || !isAvailableItemsOpen))"
            :selected-tab="selectedTab"
            :is-full-width="isFullWidth"
            :view-types="viewTypes"
            :vehicle="vehicle"
            :edit-item-obj="editItemObj"
            :selected-view-type="selectedViewType"
            :fully-expanded="editItemObj.isFullyExpanded"
            :next-line-number="nextLineNumber"
            @onItemCopied="onItemCopied"
            @focusNextRow="id => handleFocusNextRow(id)"
            @newItemAdded="newItemAdded"
            @handleItemRowClick="handleItemRowClick"
            @itemRowClicked="handleItemRowClick"
            @handlePresentAs="itemId => $emit('handlePresentAs', itemId)" />

        </div>
        <!----------------- Right hand section parts, etc ------------------------->

        <!----------------------------PARTS ----------------------->
        <div class="cell">
          <div class="mb-4"
            v-if="(selectedTab.type == ItemCategoryTypes.PART || selectedViewType != viewTypes.tabbedView)
              && ((isAvailableItemsOpen && avaliableItemsType === 'PARTS') || !isAvailableItemsOpen)">
            <quote-builder-part-component :inner-value="innerValue"
              :selected-tab="selectedTab"
              :is-full-width="isFullWidth"
              :view-types="viewTypes"
              :edit-item-obj="editItemObj"
              :selected-view-type="selectedViewType"
              :fully-expanded="editItemObj.isFullyExpanded"
              :vehicle="vehicle"
              :next-line-number="nextLineNumber"
              @onItemCopied="onItemCopied"
              @focusNextRow="id => handleFocusNextRow(id)"
              @newItemAdded="newItemAdded"
              @handleItemRowClick="handleItemRowClick"
              @itemRowClicked="handleItemRowClick"
              @handlePresentAs="itemId => $emit('handlePresentAs', itemId)" />
          </div>

          <!---------------------- MISC ----------------------->
          <div class="mb-4"
            v-if="(selectedTab.type == ItemCategoryTypes.MISC || selectedViewType != viewTypes.tabbedView)
              && ((isAvailableItemsOpen && avaliableItemsType === 'MISC') || !isAvailableItemsOpen)">

            <quote-builder-misc-component :inner-value="innerValue"
              :selected-tab="selectedTab"
              :is-full-width="isFullWidth"
              :view-types="viewTypes"
              :edit-item-obj="editItemObj"
              :selected-view-type="selectedViewType"
              :fully-expanded="editItemObj.isFullyExpanded"
              :vehicle="vehicle"
              :next-line-number="nextLineNumber"
              @onItemCopied="onItemCopied"
              @focusNextRow="id => handleFocusNextRow(id)"
              @newItemAdded="newItemAdded"
              @handleItemRowClick="handleItemRowClick"
              @itemRowClicked="handleItemRowClick"
              @handlePresentAs="itemId => $emit('handlePresentAs', itemId)" />
          </div>

          <!------------------ SUBL ------------------------>
          <div v-if="(selectedTab.type == ItemCategoryTypes.SUBL || selectedViewType != viewTypes.tabbedView)
            && ((isAvailableItemsOpen && avaliableItemsType === 'SUBLETS') || !isAvailableItemsOpen)">
            <quote-builder-sublet-component :inner-value="innerValue"
              :selected-tab="selectedTab"
              :is-full-width="isFullWidth"
              :view-types="viewTypes"
              :edit-item-obj="editItemObj"
              :selected-view-type="selectedViewType"
              :fully-expanded="editItemObj.isFullyExpanded"
              :vehicle="vehicle"
              :next-line-number="nextLineNumber"
              @onItemCopied="onItemCopied"
              @focusNextRow="id => handleFocusNextRow(id)"
              @newItemAdded="newItemAdded"
              @handleItemRowClick="handleItemRowClick"
              @itemRowClicked="handleItemRowClick"
              @handlePresentAs="itemId => $emit('handlePresentAs', itemId)" />
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import QuoteBuilderLabourComponent from './ItemTypeComponents/quoteBuilderLabour/QuoteBuilderLabourComponent.vue'
import QuoteBuilderPartComponent from './ItemTypeComponents/quoteBuilderParts/QuoteBuilderPartComponent.vue'
import QuoteBuilderMiscComponent from './ItemTypeComponents/quoteBuilderMisc/QuoteBuilderMiscComponent.vue'
import QuoteBuilderSubletComponent from './ItemTypeComponents/quoteBuilderSublets/QuoteBuilderSubletComponent.vue'
import QuoteBuilderOtherLabourComponent from './ItemTypeComponents/quoteBuilderOtherLabour/QuoteBuilderOtherLabourComponent.vue'
import { ItemCategoryTypes } from '@/enums'
// import _debounce from 'lodash/debounce'

export default {
  name: 'QuoteBuilderItemsView',
  components: {
    QuoteBuilderLabourComponent,
    QuoteBuilderPartComponent,
    QuoteBuilderMiscComponent,
    QuoteBuilderSubletComponent,
    QuoteBuilderOtherLabourComponent
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    selectedViewType: {
      type: String,
      required: true
    },
    viewTypes: {
      type: Object,
      required: true
    },
    selectedTab: {
      type: Object,
      required: true
    },
    vehicle: {
      type: Object,
      required: true
    },
    innerValue: {
      type: Object,
      required: true
    },
    isAvailableItemsOpen: {
      type: Boolean,
      default: false
    },
    avaliableItemsType: {
      type: String,
      default: ''
    },
    isPresentAsModalActive: {
      type: Boolean,
      default: false
    },
    nextLineNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      editItemObj: {
        id: null,
        isFullyExpanded: false
      },
      // sublet data
      sublets: null
    }
  },
  computed: {
    ItemCategoryTypes() {
      return ItemCategoryTypes
    },
    isFullWidth() {
      return this.selectedViewType === this.viewTypes.singleColView || this.selectedViewType === this.viewTypes.tabbedView
    }
  },
  mounted() {
    // listen for click outside of the component
    document.addEventListener('click', this.handleClickOutside)
    window.addEventListener('keydown', this.handleEnterKey)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
    window.removeEventListener('keydown', this.handleEnterKey)
  },
  methods: {
    newItemAdded(id) {
      this.editItemObj.id = id
      this.editItemObj.isFullyExpanded = true
      const elementId = id + '-itemDesc'
      this.$nextTick(() => {
        // get the element by id and focus on it
        const element = document.getElementById(elementId)
        /// if element has a child element, focus on the child element
        if (element && element.children.length > 0) {
          element.children[0].focus()
        } else if (element) element.focus()
      })
    },

    handleEnterKey(ev) {
      if (ev.ctrlKey && ev.key === 'x') {
        this.editItemObj.isFullyExpanded = !this.editItemObj.isFullyExpanded
      } else if (ev.key === 'Tab') {
        if (!this.editItemObj.id) {
          // quote builder item rows (labour, parts, misc, sublet)
          const elements = document.querySelectorAll('.quote-builder-item-row:not(.is-hidden)')
          if (!elements) return
          // Check if any element is already selected
          const selected = elements.values().find((el) => el.classList.contains('is-selected'))
          // if no element is selected, select the first element
          if (!selected) {
            ev.preventDefault()
            const elementId = elements[0].id
            this.editItemObj.id = elementId
            // focus on the first field in the element (description)
            this.$nextTick(() => {
              const element = document.getElementById(elementId + '-itemDesc')
              // If element has a child element, focus on the child element
              if (element && element.children.length > 0) {
                element.children[0].focus()
              } else if (element) {
                element.focus()
              }
            })
          }
        }
      }
    },

    handleFocusNextRow(id) {
      // elements with class quote-builder-item-row and not have strike-through-line & is-hidden
      const elements = document.querySelectorAll('.quote-builder-item-row:not(.strike-through-line):not(.is-hidden)')
      console.log(elements)
      const elementsArray = Array.from(elements)
      const index = elementsArray.findIndex((el) => el.id == id)
      // if the selected element is the last element, get first element, else get the next element
      const nextElement = index === elements.length - 1 ? elements[0] : elements[index + 1]
      // get the next element id
      const elementId = nextElement.id
      // set the editItemObj id to the next element id
      this.editItemObj.id = elementId

      // focus on the element
      this.$nextTick(() => {
        const element = document.getElementById(elementId + '-itemDesc')
        // If element has a child element, focus on the child element
        if (element && element.children.length > 0) {
          element.children[0].focus()
        } else if (element) {
          element.focus()
        }
      })
    },

    handleClickOutside(event) {
      // if class is button, return
      if (event.target.classList.contains('button')) return
      // if modal active, want to save the state before modal was active
      if (this.isPresentAsModalActive) return
      this.editItemObj.id = null
      this.editItemObj.isFullyExpanded = false
    },

    handleItemRowClick(item, event, expand, close = false) {
      if (close) {
        this.editItemObj.id = null
        this.editItemObj.isFullyExpanded = false
        return
      }
      if (!!expand) this.editItemObj.isFullyExpanded = true
      // if the item is already selected, return
      if (this.editItemObj.id === item.quoteItemId) return
      this.editItemObj.id = item.quoteItemId
      let elementId = event.target.id
      if (!elementId) elementId = item.quoteItemId + '-itemDesc'
      // add a bit of delay before focusing on the element // not doing this will not focus on the element as it is not rendered yet
      setTimeout(() => {
        const element = document.getElementById(elementId)
        // If element has a child element, focus on the child element
        if (element && element.children.length > 0) {
          element.children[0].focus()
        } else if (element) {
          element.focus()
        }
      }, 75)
    },

    getItemAssessments(item) {
      const itemAssessments = this.itemsAssessments.find((i) => i.quoteItemId === item.quoteItemId)
      if (itemAssessments) {
        return itemAssessments.assessments
      }
      return []
    },
    async getVendors() {
      this.isVendorLoading = true
      const vm = this
      this.initArrays(this.activeItems.length)
      const promises = this.activeItems.map(async function (item, index) {
        const vendorId = item.vendorId || Guid.empty()
        const vendorFilter = {
          id: vendorId,
          name: '',
          pageIndex: 1,
          pageSize: 50
        }
        vm.getVendor(index, vendorFilter)
      })
      await Promise.all(promises)
      this.isVendorLoading = false
    },
    initArrays(length) {
      this.vendors = new Array(length)
      this.vendorFilters = new Array(length)
      this.selectedVendors = new Array(length)
      let size = length
      while (size--) {
        this.vendors.splice(size, 1, null)
        this.vendorFilters.splice(size, 1, null)
        this.selectedVendors.splice(size, 1, null)
      }
    },
    onItemCopied(item) {
      this.$toast.open({
        message: 'Item copied to ' + item.itemType,
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })

      this.editItemObj.id = item.quoteItemId
      this.editItemObj.isFullyExpanded = true
    }
  }
}
</script>

<style lang="scss">
.is-quote-item-field-editable {
  cursor: text;
}

.quote-builder-item-row {
  position: relative;

  &.is-selected {
    z-index: 9999;
  }

  &.is-delete-real-item * {
    text-decoration: line-through;
  }

  &.strike-through-line {
    position: relative;
  }

  &.strike-through-line div {
    opacity: 0.9;
  }

  &.strike-through-line::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: 1px solid rgba($color: #000000, $alpha: 1);
    transform: translateY(-50%);
  }
}

.pos-relative {
  position: relative;
  z-index: 99;
}
</style>
