<template>
  <div>
    <!---------------------- MISC ----------------------->
    <div class="mb-4"
      v-if="selectedTab.type == ItemCategoryTypes.MISC || selectedViewType != viewTypes.tabbedView">
      <quote-builder-misc-headings-component :total-amount="MiscTotal"
        :is-full-width="isFullWidth" />
      <div class="rows">
        <draggable @change="itemMoved($event, ItemCategoryTypes.MISC)"
          v-model="innerValue.miscs"
          @over.prevent
          @enter.prevent
          v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
          :clone="$event => cloneHandler($event, ItemCategoryTypes.MISC)"
          :group="{ name: ItemCategoryTypes.MISC, pull: 'clone', put: true }"
          handle=".item-drag-handle">
          <quote-builder-misc-item v-for="item in miscItems"
            :key="item.id"
            :inner-value="innerValue"
            :is-full-width="isFullWidth"
            :items-assessments="itemsAssessments"
            :item="item"
            :edit-item-id="editItemObj.id"
            :fully-expanded="editItemObj.isFullyExpanded"
            :vehicle="vehicle"
            @focusNextRow="id => $emit('focusNextRow', id)"
            @handlePresentAs="itemId => $emit('handlePresentAs', itemId)"
            @itemRowClicked="(value, event, expand, close) => $emit('handleItemRowClick',value, event, expand, close)" />
        </draggable>
      </div>
      <div>
        <div class="button has-text-primary"
          @click="addBlankItem()">
          + Add Item
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteBuilderMiscHeadingsComponent from './QuoteBuilderMiscHeadingsComponent.vue'
import QuoteBuilderMiscItem from './QuoteBuilderMiscItem.vue'
import draggable from 'vuedraggable'
import { ItemCategoryTypes } from '@/enums'
import cloneDeep from 'lodash.clonedeep'
import { QuoteItemModel } from '@/classes/viewmodels'

import { QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin } from '../../mixins'
export default {
  name: 'QuoteBuilderMiscComponent',
  components: {
    QuoteBuilderMiscHeadingsComponent,
    QuoteBuilderMiscItem,
    draggable
  },
  mixins: [QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin],
  props: {
    innerValue: {
      type: Object,
      required: true
    },
    isFullWidth: {
      type: Boolean,
      required: true
    },
    editItemObj: {
      type: Object
    },
    selectedTab: {
      type: Object
    },
    selectedViewType: {
      type: String
    },
    isAudanet: {
      type: Boolean,
      default: false
    },
    viewTypes: {
      type: Object
    },
    vehicle: {
      type: Object
    },
    nextLineNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    ItemCategoryTypes() {
      return ItemCategoryTypes
    },
    miscItems() {
      return this.innerValue.miscs
    },
    MiscTotal() {
      return this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.miscs), ItemCategoryTypes.MISC)
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    itemsAssessments() {
      return this.getItemsAssessments(cloneDeep(this.innerValue.miscs), cloneDeep(this.innerValue.quoteAssessments))
    }
  },
  mounted() {
    this.$v.entity.miscs.$touch()
  },
  methods: {
    cloneHandler(item, type) {
      // change the quoteItemId to a new guid so we can find it later and change to correct type on drop
      // TODO: looks like this doesn't work, leave it as is
      // item.quoteItemId = Guid.newGuid()
      return item
    },
    itemMoved(event, type) {
      // if an item was dragged from one list to another, i.e from RR to RWA, Parts to RR etc.
      if (event?.added) {
        const item = event.added.element
        this.handleItemAdded(item, type, event.added.newIndex)
      }

      let sortNo = Math.min(...this.innerValue.miscs.map((i) => i.sortNo))
      this.innerValue.miscs.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },

    handleItemAdded(item, type, index) {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', item.itemDesc, ItemCategoryTypes.MISC)
      newItem.lineNumber = this.nextLineNumber
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.value = 0
      newItem.side = ''
      newItem.sortNo = this.innerValue.miscs.length ? Math.max(...this.innerValue.miscs.map((i) => i.sortNo)) + 1 : 1
      this.innerValue.lines = this.nextLineNumber + 1
      // replace the item with the new item
      this.innerValue.miscs.splice(index, 1, newItem)

      this.$emit('onItemCopied', newItem)
    },

    addBlankItem() {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, '', '', ItemCategoryTypes.MISC)
      newItem.lineNumber = this.nextLineNumber
      newItem.itemNo = this.$filters.pad(newItem.lineNumber, 4)
      newItem.value = 0
      newItem.side = ''
      newItem.sortNo = this.innerValue.miscs.length ? Math.max(...this.innerValue.miscs.map((i) => i.sortNo)) + 1 : 1
      this.innerValue.miscs.splice(this.innerValue.miscs.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })

      this.$nextTick(() => {
        this.$emit('newItemAdded', newItem.quoteItemId)
      })
    }
  }
}
</script>

<style scoped>
/* Your component's styles go here */
</style>