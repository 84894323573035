<template>
  <div class="cell center">
    <div v-if="!!item.presentAs.length"
      @click="handlePresentAsClick"
      class="is-flex is-clickable"
      style="width: 100%;">
      <div v-for="(split, index) in item.presentAs"
        :key="split.itemType"
        class="pill is-size-7 is-flex is-justify-content-center has-text-light"
        :style="{ width: `${split.percent}%` }"
        :class="[
          index > 0 ? 'has-background-success' : 'has-background-primary',
          { 'isFirst': index === 0 },
          { 'isLast': index === item.presentAs.length - 1 }
        ]">
        <span>{{ split.itemType }}</span>
      </div>

      <tippy arrow
        placement="top"
        :theme="`light`">
        <div :style="{ width: '120px' }">
          <div class="mb-4">
            <span class="is-size-6 mb-3">Present As</span>
          </div>
          <div class="is flex is-flex-row w-100">
            <div class="w-100 part-row is-flex is-flex-row mb-2"
              v-for="(split, index) in item.presentAs"
              :key="item.quoteItemId + index">
              <div class="is-flex is-justify-content-space-between w-100">
                <div class="pill isFirst has-background-success is-flex is-justify-content-center has-text-white"
                  style="width: 50%;">
                  <span>
                    {{ split.itemType }}
                  </span>
                </div>
                <div class="pill isLast is-flex is-justify-content-center has-border"
                  style="width: 50%;">{{ split.percent }}%</div>
              </div>

            </div>
          </div>
        </div>
      </tippy>

    </div>

    <div v-else
      @click="handlePresentAsClick"
      class="pill is-size-7 is-flex is-justify-content-center isFirst isLast has-background-grey-light is-clickable"
      :style="{ width: '100%' }">
      <span>Present As</span>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PresentAsComponent',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    handlePresentAsClick() {
      this.$emit('handlePresentAs')
    }
  }
}
</script>

<style lang="scss" scoped>
.pill {
  padding: 2px;
  &.isFirst {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.isLast {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.has-border {
    border: 1px solid rgb(153, 153, 153);
    border-left: 0px;
  }
}

.w-100 {
  width: 100%;
}
/* Your component styles go here */
</style>